<template>
  <div id="app">
    <v-app id="inspire">
      <v-app-bar app dense color="primary" dark flat>
        <v-app-bar-nav-icon>
          <v-icon @click="goToAppCategory"> mdi-apps </v-icon>
        </v-app-bar-nav-icon>

        <v-toolbar-title>{{ appName }}</v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-toolbar-items>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark v-bind="attrs" v-on="on" text small>
                {{ email }}
                <v-icon small> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title @click="clearCached"
                  >Xoá dữ liệu đệm</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="logout">Đăng xuất</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-items>
      </v-app-bar>
      <v-main>
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import json from "../public/config.json";

export default {
  data: () => ({ appName: "Vietmap Internal App", email: "", config: json }),
  updated() {
    let routeName = this.$route.name;
    let appName = localStorage.getItem("AppName");
    this.appName =
      routeName != "home"
        ? appName != undefined
          ? appName
          : "Vietmap Internal App"
        : "Vietmap Internal App";

    if (routeName != "microsoftcallback") {
      let email = localStorage.getItem("Email");
      if (email == null) {
        location.href =
          "https://sso.vietmap.vn/#/login?force=true&appId=process.maker&returnUrl=http%3A%2F%2Fapps.vietmap.vn%2F%23%2Fmicrosoftcallback"; //this.config.SsoUrl;
            // "https://sso.vietmap.vn/#/login?force=true&appId=process.maker&returnUrl=http%3A%2F%2Flocalhost%3A8080%2F%23%2Fmicrosoftcallback"; //this.config.SsoUrl;
      } else {
        this.email = email;
      }
    }
  },
  methods: {
    goToAppCategory() {
      location.href = "#/";
    },
    clearCached() {
      var Backlen = history.length;   
      history.go(-Backlen);
      window.location.href= "https://sso.vietmap.vn/#/login?force=true&appId=process.maker&returnUrl=http%3A%2F%2Fapps.vietmap.vn%2F%23%2Fmicrosoftcallback";
      // let message = localStorage.getItem("Email");
      // var frameEle = document.getElementById("iframe");
      // console.log(frameEle);
      // frameEle.contentWindow.postMessage(message, "*");
      // location.reload(true);
      
    },
    logout() {
      localStorage.removeItem("Email");
      location.reload();
    },
  },
};
</script>
<style scoped>
.v-list-item {
  cursor: pointer !important;
}
</style>